import { FORM_ERROR } from 'final-form'
import {
  createValidator,
  empty,
  ErrorObject,
  oneRequired,
  required,
  requiredRichText,
  richTextMaxLength,
} from 'util/validation'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'

export const cuidadoCompartilhadoFormValidator = (
  isSomenteCiap: boolean,
  unidadeSaudeId: ID,
  isPilotoCuidadoCompartilhado2Enabled: boolean,
  encaminhamentos: readonly CuidadoCompartilhadoPlanoModel[],
  isAtendimentoOdonto: boolean
) => {
  return createValidator<CuidadoCompartilhadoPlanoModel>(
    {
      prioridade: [required],
      problemasECondicoes: [required],
      discussao: [requiredRichText],
    },
    (values: CuidadoCompartilhadoPlanoModel, errors: ErrorObject<CuidadoCompartilhadoPlanoModel>) => {
      const isGuiaEncaminhamento = values?.cbo && !values?.cbo?.isVinculado

      if (isGuiaEncaminhamento) {
        return validateGuiaEncaminhamento(values, errors, isSomenteCiap, encaminhamentos)
      } else {
        return validateCuidadoCompartilhado(
          values,
          errors,
          unidadeSaudeId,
          isSomenteCiap,
          isAtendimentoOdonto,
          isPilotoCuidadoCompartilhado2Enabled
        )
      }
    }
  )
}

const validateCuidadoCompartilhado = (
  values: CuidadoCompartilhadoPlanoModel,
  errors: ErrorObject<CuidadoCompartilhadoPlanoModel>,
  unidadeSaudeId: ID,
  isSomenteCiap: boolean,
  isAtendimentoOdonto: boolean,
  isPilotoCuidadoCompartilhado2Enabled: boolean
): ErrorObject<CuidadoCompartilhadoPlanoModel> => {
  errors.discussao = richTextMaxLength(1000)(values.discussao)
  errors.cidadaoAceitaAtendTic = required(values.cidadaoAceitaAtendTic)

  const isEMulti = !isPilotoCuidadoCompartilhado2Enabled || unidadeSaudeId === values.unidadeSaude?.id
  if (isEMulti || isAtendimentoOdonto) {
    errors.lotacao = required(values.lotacao)
  } else errors.cbo = required(values.cbo)

  if (isPilotoCuidadoCompartilhado2Enabled && !isAtendimentoOdonto) errors.unidadeSaude = required(values.unidadeSaude)

  if (isSomenteCiap) {
    errors.problemasECondicoes = {
      ciap: required(values.problemasECondicoes?.ciap),
      cid10: empty(values.problemasECondicoes?.cid10),
    }
  } else {
    errors.problemasECondicoes = oneRequired([values.problemasECondicoes?.ciap, values.problemasECondicoes?.cid10])
  }

  return errors
}

const validateGuiaEncaminhamento = (
  values: CuidadoCompartilhadoPlanoModel,
  errors: ErrorObject<CuidadoCompartilhadoPlanoModel>,
  isSomenteCiap: boolean,
  encaminhamentos: readonly CuidadoCompartilhadoPlanoModel[]
): ErrorObject<CuidadoCompartilhadoPlanoModel> => {
  errors.cbo = required(values.cbo)
  errors.discussao = richTextMaxLength(2000)(values.discussao)
  errors.lotacao = empty(values.lotacao)
  errors.unidadeSaude = empty(values.unidadeSaude)
  errors.cidadaoAceitaAtendTic = empty(values.cidadaoAceitaAtendTic)

  isEncaminhamentoDuplicado(encaminhamentos, values, errors)

  if (isSomenteCiap) {
    errors.problemasECondicoes = {
      ciap: required(values.problemasECondicoes?.ciap),
      cid10: required(values.problemasECondicoes?.cid10),
    }
  } else {
    errors.problemasECondicoes = {
      cid10: required(values.problemasECondicoes?.cid10),
    }
  }
  return errors
}

const isEncaminhamentoDuplicado = (
  encaminhamentos: readonly CuidadoCompartilhadoPlanoModel[],
  values: CuidadoCompartilhadoPlanoModel,
  errors: ErrorObject<CuidadoCompartilhadoPlanoModel>
) => {
  return encaminhamentos.some((encaminhamento) => {
    if (
      encaminhamento._id !== values._id &&
      encaminhamento.cbo.cbo.id === values.cbo.cbo.id &&
      encaminhamento.problemasECondicoes.cid10?.id === values.problemasECondicoes?.cid10?.id
    ) {
      errors[FORM_ERROR] = 'Já existe uma Guia de encaminhamento cadastrada para este CBO e Problemas/condições (CID10)'
      return true
    }
    return false
  })
}
