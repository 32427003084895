import { isBefore } from 'date-fns'

import { AcaoAgendamentoEnum, StatusAcaoAgendamento } from './model-acoesAgendamento'

export function getStatusAcoesConsultaAD({
  dataAtual,
  dataConsulta,
}: {
  dataAtual: Date
  dataConsulta: Date
}): Record<AcaoAgendamentoEnum, StatusAcaoAgendamento> {
  const isPassado = isBefore(dataConsulta, dataAtual)

  return {
    VISUALIZAR_PRONTUARIO: { visible: true, disabled: false, tooltip: 'Visualizar Prontuário' },

    CANCELAR_AGENDAMENTO: {
      visible: true,
      disabled: isPassado,
      tooltip: isPassado ? 'Não é possível cancelar agendamentos passados' : 'Cancelar',
    },

    INSERIR_LISTA_ATENDIMENTO: { visible: false },
    REMOVER_LISTA_ATENDIMENTO: { visible: false },
    INFORMAR_FALTA_CIDADAO: { visible: false },
    REMOVER_FALTA_CIDADAO: { visible: false },
  }
}
